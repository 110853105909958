import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Logo from "../images/Logo.svg"
import MenuIcon from "../images/menu-black.svg"
import style from "./header.module.scss"
import VisibilitySensor from "react-visibility-sensor"

const Header = ({ hero = true, headerBackground }) => {
  const [visibility, setVisibility] = useState(true)
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <header
        className={[
          style.header,
          hero ? style.hero : "",
          headerBackground ? style.image : "",
          visibility ? style.scrollOverMenu : "",
        ].join(" ")}
        style={{
          backgroundImage: headerBackground ? `url(${headerBackground})` : null,
          backdropFilter:
            !headerBackground && style.hero && menuOpen ? "blur(20px)" : "",
          WebkitBackdropFilter:
            !headerBackground && style.hero && menuOpen ? "blur(20px)" : "",
          backgroundColor: headerBackground ? "unset" : null,
        }}
      >
        <Link to="/" className={style.logo}>
          <Logo></Logo>
        </Link>
        <label className={style.menuLabel} htmlFor="HeaderMenu">
          <MenuIcon fill="#404C53" />
        </label>
        <input
          className={style.menuController}
          type="checkbox"
          id="HeaderMenu"
          checked={menuOpen}
          onChange={event => setMenuOpen(event.target.checked)}
        />
        <nav>
          <Link activeClassName={style.active} to="/">
            首頁
          </Link>
          <Link activeClassName={style.active} to="/about-ixdtw2021/">
            關於大會
          </Link>
          <Link activeClassName={style.active} to="/speaker/">
            講者
          </Link>

          <div>
            <span>議程</span>
            <div className={style.inner}>
              <Link activeClassName={style.active} to="/agenda/2021-05-14/">
                5月14日議程
              </Link>
              <Link activeClassName={style.active} to="/agenda/2021-05-15/">
                5月15日議程
              </Link>
              <Link activeClassName={style.active} to="/agenda/2021-05-16/">
                5月16日議程
              </Link>
              <Link activeClassName={style.active} to="/agenda/qa/">
                線上 QA
              </Link>
            </div>
          </div>
          <Link activeClassName={style.active} to="/press/">
            報導
          </Link>
        </nav>
      </header>
      <VisibilitySensor
        offset={{ top: -100 }}
        onChange={val => setVisibility(val)}
      >
        <div
          style={{
            position: "absolute",
            visibility: false,
            height: 1,
            width: 1,
          }}
        ></div>
      </VisibilitySensor>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
