import React from "react"
import style from "./footer.module.scss"

import FacebookMessenger from "../images/fa-brands-facebook-messenger.svg"
import Facebook from "../images/fa-brands-facebook-square.svg"
import Instagram from "../images/fa-brands-instagram.svg"
import Medium from "../images/fa-brands-medium.svg"
import Mail from "../images/mail.svg"
/* eslint-disable */
const Footer = () => {
  return (
    <>
      <footer className={style.footer}>
        <div className={style.social}>
          <a href="https://m.me/ixda.tw">
            <FacebookMessenger />
          </a>
          <a href="https://fb.me/ixda.tw">
            <Facebook />
          </a>
          <a href="https://www.instagram.com/ixda_taiwan/">
            <Instagram />
          </a>
          <a href="https://medium.com/ixda-taiwan">
            <Medium />
          </a>
          <a href="mailto:service@ixda.org.tw">
            <Mail />
          </a>
        </div>
        <div className={style.copyright}>
          &copy; 2021 IxDA Taiwan. All Right Reserved.
        </div>
      </footer>
    </>
  )
}
/* eslint-enable */

export default Footer
